.postContent {
  @include wrapper;
  grid-template-rows: 1fr 50px repeat(2, 850px);

  &__article {
    grid-column: 2;
    grid-row: 1;
    background-color: $white;
    padding-top: 5rem;

    & * {
      margin: 1rem 0;
    }

    &__ad {
      margin: 1.5rem 0;
    }

    &__title {
      margin-bottom: 0;
      font-size: 3rem;

      @include respond-to("medium") {
        font-size: 2rem;
        text-align: center;
      }
    }

    & figure {
      & img {
        @include respond-to("large") {
          width: 100%;
        }
        @include respond-to("medium") {
          width: 100%;
        }
      }
    }

    & ul {
      margin-left: 1.2rem;

      & li {
        margin: 1rem 0;
      }
    }

    & p {
      letter-spacing: 1px;
      line-height: 30px;
      text-align: justify;

      & img {
        width: 90%;
        @include respond-to("large") {
          width: 80%;
        }
        @include respond-to("medium") {
          width: 80%;
        }
      }
    }

    &__download {
      font-size: 1.5rem;
      color: $black;
      font-weight: normal;
      position: relative;
      margin-left: 3rem;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &::before {
        font-family: "fontello";
        content: "\e804";
        position: absolute;
        left: -3rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__share {
      display: flex;

      & p {
        margin-right: 0.5rem;
      }

      & button {
        margin: 0 0.3rem;
      }
    }
  }

  &__another {
    grid-row: 2;
    grid-column: 2;
    background-color: $white;
    display: flex;
    justify-content: space-between;

    &__prev,
    &__next {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: $black;

      & > i {
        font-size: 0.8rem;
        transition: 1s ease;
      }
    }

    &__prev {
      &:hover i {
        animation: arrow-animation-prev 1s ease;
      }

      & > i {
        margin-right: 0.5rem;
      }
    }

    &__next {
      &:hover i {
        animation: arrow-animation-next 1s ease;
      }

      & > i {
        margin-left: 1rem;
      }
    }

    @keyframes arrow-animation-prev {
      from {
        transform: translateX(0.5rem);
      }
      to {
        transform: translateX(-0.5rem);
      }
    }

    @keyframes arrow-animation-next {
      from {
        transform: translateX(-0.5rem);
      }
      to {
        transform: translateX(0.5rem);
      }
    }
  }

  &__more {
    grid-row: 3;
    grid-column: 2;
    background-color: $white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      padding: 1rem;
      position: relative;

      @include respond-to("small") {
        padding: 1rem;
      }

      & h2 {
        font-weight: 400;
        font-size: 2rem;

        @include respond-to("small") {
          font-size: 1.2rem;
        }
      }

      &::before {
        @include decorative-line-left;
      }

      &::after {
        @include decorative-line-right;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      position: relative;
      margin-top: 1rem;

      @include respond-to("small") {
        grid-template-columns: 1fr;
      }
    }
  }

  &__comments {
    grid-row: 4;
    grid-column: 2;
    background-color: $white;
    padding-bottom: 2rem;
    padding-top: 2rem;

    &__title {
      &::after {
        font-family: "fontello";
        content: "\f27b";
        padding-left: 1rem;
      }
    }

    &__container {
      list-style-type: none;
      margin-bottom: 2rem;

      @include respond-to("only-desktop") {
        overflow-y: scroll;
        max-height: 400px;
      }

      &__item {
        padding: 1rem;
        border-bottom: 1px solid $black;

        &__box {
          &:nth-child(2) {
            margin-left: 4rem;
            position: relative;

            &::before {
              font-family: "fontello";
              content: "\f178";
              position: absolute;
              left: -2.5rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          & > * {
            padding: 0.5rem;
          }

          & div {
            font-size: 1.1rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            width: 100%;

            @include respond-to("small") {
              font-size: 0.9rem;
            }

            & img {
              margin-right: 1rem;

              @include respond-to("small") {
                width: 10%;
                margin-right: 0.5rem;
              }
            }

            & span {
              font-weight: normal;
              font-size: 1rem;

              @include respond-to("small") {
                display: none;
              }

              &::after {
                font-family: "fontello";
                content: "\e803";
                padding-left: 0.3rem;
              }
            }
          }
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include respond-to("medium") {
        width: 100%;
      }

      & * {
        margin-top: 1rem;
      }

      & input {
        padding: 1rem;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $black;
      }

      & textarea {
        padding: 1rem;
        border-radius: 0;
        height: 100px;
      }

      & button {
        @include button("Dodaj komentarz");
      }
    }
  }
}
