.home {
  @include wrapper;

  &__header {
    @include center;
    grid-column: 2;
    background-color: $white;
    overflow: hidden;

    @include respond-to("medium") {
      padding: 2rem 0 3rem 0;
    }
  }

  &__about {
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-column: 2;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      padding: 2rem;
      position: relative;

      @include respond-to("small") {
        padding: 1rem;
      }

      & h1 {
        font-weight: 400;
        font-size: 2rem;

        @include respond-to("small") {
          font-size: 1.2rem;
        }
      }

      &::before {
        @include decorative-line-left;
      }

      &::after {
        @include decorative-line-right;
      }
    }

    &__text {
      letter-spacing: 1px;
      line-height: 30px;
      text-align: justify;
    }

    &__button {
      margin-top: 1rem;
      @include button("Znajdź coś dla siebie!");

      @include respond-to("medium") {
        margin-bottom: 2rem;
      }
    }
  }

  &__posts {
    grid-column: 2;
    background-color: $white;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      padding: 2rem;
      position: relative;

      @include respond-to("small") {
        padding: 1rem;
      }

      & h2 {
        font-weight: 400;
        font-size: 2rem;

        @include respond-to("small") {
          font-size: 1.2rem;
        }
      }

      &::before {
        @include decorative-line-left;
      }

      &::after {
        @include decorative-line-right;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      row-gap: 1rem;
      column-gap: 0.5rem;
      position: relative;
      width: 100%;

      @include respond-to("small") {
        grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
      }
    }
  }
}
