.about {
  @include wrapper;

  &__container {
    grid-column: 2;
    background-color: $white;
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;

    @include respond-to("large") {
        flex-direction: column;
    }

    &__left {
      width: 100%;

      &__title {
        font-size: 3rem;   
        margin-bottom: 1.5rem;  

        @include respond-to("medium") {
          font-size: 2rem;
        }
      }

      &__paragraph {
        padding: 0 2rem 1rem 0;
        letter-spacing: 1px;
        line-height: 30px;
        text-align: justify;

        & a {
            color: $black;
            @include respond-to("small") {
              font-size: .8rem;
            }
        }
      }
    }

    &__right {
      @include respond-to("only-desktop") {
        margin-top: 5rem;
      }
      
      & img {
        width: 100%;
      }
    }
  }
}
