.slider {

    &__item {
        z-index: 1;
        font-size: 3rem;
        font-weight: normal;
        letter-spacing: .8rem;
        color: $black;
        position: relative;
        background-color: rgba(255, 255, 255, 0.212);
        padding: 2rem;

        @include respond-to("large") {
            font-size: 2rem;
            letter-spacing: .5rem;
        }

        @include respond-to("small") {
            font-size: 1.5rem;
            letter-spacing: .3rem;
        }

        &::after {
            content: "";
            position: absolute;
            width: 50%;
            height: 100%;
            left: 50%;
            bottom: 0;
            border-bottom: .1rem solid $black;
            transform: translateX(-50%);
        }
    }
}