@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (max-width: 650px) {
      @content;
    }
  } @else if $breakpoint == "medium" {
    @media (max-width: 800px) {
      @content;
    }
  } @else if $breakpoint == "large" {
    @media (max-width: 1140px) {
      @content;
    }
  } @else if $breakpoint == "only-desktop" {
    @media (min-width: 650px) {
      @content;
    }
  }
}

@mixin wrapper {
  display: grid;
  grid-template-columns: 10vw 1fr 10vw;

  & > * {
    padding: 0 3rem;
  }

  @include respond-to("medium") {
    display: flex;
    flex-direction: column;
  }
}

@mixin hamburger-pseudoelements {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-top: 0.2rem solid $black;
  transition: transform 0.3s ease-out;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin decorative-line-left {
  content: "";
  position: absolute;
  top: 50%;
  left: -5rem;
  width: 5rem;
  height: 0.1rem;
  background-color: $black;
  transform: translateY(-50%);

  @include respond-to("small") {
    width: 1rem;
    left: -1rem;
  }
}

@mixin decorative-line-right {
  content: "";
  position: absolute;
  top: 50%;
  right: -5rem;
  width: 5rem;
  height: 0.1rem;
  background-color: $black;
  transform: translateY(-50%);

  @include respond-to("small") {
    width: 1rem;
    right: -1rem;
  }
}

@mixin button($text) {
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 0.1rem solid $black;

  @include respond-to("only-desktop") {
    &:hover::before {
      transform: translateY(0);
    }
  
    &::before {
      content: $text;
      color: $white;
      @include center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      transform: translateY(-100%);
      transition: transform 0.2s ease-in-out;
    }
  }
}
