.search {
    position: relative;
    display: flex;

    &__label {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    &__box {
        position: relative;

        &__input {
            padding: .5rem 1rem .5rem .5rem;
            margin-right: 1rem;
            border: none;
            border-bottom: 1px solid $black;
            font-size: .9rem;
            border-radius: 0;

            &:focus {
                outline: 0;
            }
    
            @include respond-to("large") {
                font-size: .7rem;
            }
    
            @include respond-to("small") {
                font-size: 16px;
            }
        }

        &__tips {
            position: absolute;
            list-style-type: none;
            padding: .3rem 0;
            background-color: $white;
            width: 100%;
            left: 0;
            z-index: 1;
            display: flex;
            flex-direction: column;

            & > a {
                padding: .4rem .6rem;
                font-size: .8rem;
                cursor: pointer;
                text-decoration: none;
                color: $black;
            }
        }
    }

    &__button {
        border: none;
        background-color: $white;
        cursor: pointer;
        margin-right: 1rem;
    }
}