.categoryPosts {
  @include wrapper;
  grid-template-rows: 100px 1fr;

  &__title {
    grid-column: 2;
    grid-row: 1;
    background-color: $white;
    padding-top: 2.5rem;

    & h3 {
        font-style: normal;
        font-weight: normal;

        @include respond-to("medium") {
            font-size: .8rem;
        }

        & span {
            font-style: italic;
            font-weight: bold;
        }
    }
  }

  &__container {
    grid-column: 2;
    grid-row: 2;
    background-color: $white;
    padding-bottom: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    position: relative;

    @include respond-to("large") {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @include respond-to("medium") {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
